import { SortDirection } from '@lib/modules/table/components';

export enum VelocityLimitType {
    PER_TRANSACTION = 'PER_TRANSACTION',
    PER_CARD = 'PER_CARD',
    PER_ACCOUNT = 'PER_ACCOUNT',
    // TODO : As Per Michel comment we need to replace PER_ACCOUNT with Account thats why i added new property
    ACCOUNT = 'Account',
    // TODO END HERE
    PER_USER = 'PER_USER',
}

export enum Period {
    DAILY = 'DAILY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
    ALL_TIME = 'ALL_TIME',
    NUM_OF_DAYS = 'NUM_OF_DAYS',
}

export enum TransactionScope {
    WITHDRAWALS = 'WITHDRAWALS',
    PURCHASES = 'PURCHASES',
    OUTGOING_TRANSFERS = 'OUTGOING_TRANSFERS',
    UNLOADS = 'UNLOADS',
    SCHEME_ACCOUNT_FUNDING = 'SCHEME_ACCOUNT_FUNDING',
    QUASI_CASH = 'QUASI_CASH',
    PURCHASES_CONTACTLESS_NO_PIN = 'PURCHASES_CONTACTLESS_NO_PIN',
    LOADS = 'LOADS',
    INCOMING_TRANSFERS = 'INCOMING_TRANSFERS',
    SCHEME_CREDITS = 'SCHEME_CREDITS',
}

export enum ChannelScope {
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    WESTERNUNION = 'WESTERNUNION',
}

export enum LimitType {
    BALANCE_LIMIT = 'balancelimits',
    VELOCITY_LIMIT = 'velocitylimits',
    ADVANCED_VELOCITY_LIMIT = 'advancedlimits',
}

export interface ILimit {
    id: string;
    description: string;
    active: boolean;
    type: VelocityLimitType;
    min_amount: number;
    max_amount: number;
    currency: string;
    frequency: number;
    period: Period;
    num_of_days: number;
    transaction_scope: Array<TransactionScope>;
    channel_scope: ChannelScope;
    program_level: boolean;
    linked_to?: string;
    created: string;
    modified: string;
    effective_from: string;
    effective_to: string;
    conditions: Array<Condition>;
    name: string;
    amount: number;
    consumed_limit: number;
}

export interface ILimitList {
    data: ILimit[];
    after: string;
    has_more: boolean;
}

export interface ILimitQueryParams {
    limit: number;
    after: string;
    sort_by: string;
    order_by: SortDirection;
}

export interface Condition {
    type?: string;
    values?: string;
    active?: boolean;
}

export interface ILimitInfo {
    balance_limits: Array<ILimit>;
    velocity_limits: Array<ILimit>;
    advanced_velocity_limits: Array<ILimit>;
    user_id: string;
}

export type IMutateLimit = Omit<ILimit, 'program_level' | 'linked_to' | 'created' | 'modified'>;
