import { Injectable, Type } from '@angular/core';
import { customerTenant } from '@env/environment';
import { tenantImagesMap } from '@lib/constants/tenant.constant';

@Injectable({
    providedIn: 'root',
})
export class TenantConfigurationService {
    private agentCreationFlows: Record<string, () => Promise<Type<unknown>>> = {
        [customerTenant.FIB]: async () => (await import('@pages/customer/pages')).CreateAgentComponent,
        [customerTenant.INC]: async () => (await import('@pages/customer/pages')).CreateAgentIncComponent,
    };

    private agentDocumentationFlows: Record<string, string> = {
        [customerTenant.FIB]: '/customers/create/${id}/fib/add-new-identification',
    };

    constructor() {}

    /**
     * Checks if the given tenant is allowed based on the configured flows.
     * @param tenant - The tenant string to check.
     * @returns boolean - True if the tenant is allowed, false otherwise.
     */
    isTenantAllowed(tenant: string): boolean {
        return Object.keys(this.agentCreationFlows).some((allowedTenant) => tenant.startsWith(allowedTenant));
    }

    /**
     * Gets the dynamic loader function for agent creation based on the given tenant.
     * @param tenant - The tenant string.
     * @returns () => Promise<Type<unknown>> | undefined - A function that loads the component dynamically or undefined if no match is found.
     */
    getComponentForAgentCreation(tenant: string): (() => Promise<Type<unknown>>) | undefined {
        const allowedTenant = Object.keys(this.agentCreationFlows).find((key) => tenant.startsWith(key));
        return allowedTenant ? this.agentCreationFlows[allowedTenant] : undefined;
    }

    /**
     * Retrieves all URLs for the agent documentation flow with the provided identifier.
     * @param id - The unique identifier to replace placeholders in the URLs.
     * @returns Record<string, string> - A record containing tenant-specific documentation flow URLs.
     */
    getAgentDocumentationFlowUrls(id: string): Record<string, string> {
        const interpolatedFlows: Record<string, string> = {};
        for (const [key, url] of Object.entries(this.agentDocumentationFlows)) {
            interpolatedFlows[key] = url.replace('${id}', id);
        }
        return interpolatedFlows;
    }

    /**
     * Retrieves url of image specific to tenant if found otherwise return empty string.
     * @param tenant - The tenant string.
     * @param imgName - The image you want to lookup.
     * @returns <string> - A url of image or a empty string.
     */
    getImageSpecificToTenant(tenant: string, imgName: string): string {
        const tenantImages = tenantImagesMap[tenant.split('_')[0]];
        const defaultImages = tenantImagesMap['DEFAULT'];

        if (tenantImages && tenantImages[imgName]) {
            return tenantImages[imgName];
        } else if (defaultImages && defaultImages[imgName]) {
            return defaultImages[imgName];
        }
        return '';
    }
}
