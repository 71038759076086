export enum Permission {
    // Home
    Home = 'b:home',

    // Customer
    Customer = 'b:customer',
    CustomerAdd = 'b:customer:add',
    CustomerModify = 'b:customer:modify',
    CustomerDetails = 'b:customer:details',
    CustomerKYC = 'b:customer:kyc',
    CustomerKYCModify = 'b:customer:kyc:modify',
    CustomerChangeStatus = 'b:customer:changestatus',
    CustomerIdentifications = 'b:customer:identifications',
    CustomerIdentificationsModify = 'b:customer:identifications:modify',
    CustomerIdentificationsUploadFiles = 'b:customer:identifications:uploadfiles',
    CustomerAccounts = 'b:customer:accounts',
    CustomerAccountsAdd = 'b:customer:accounts:add',
    CustomerAccountsLinkCard = 'b:customer:accounts:linkcard',
    CustomerAccountsChangeStatus = 'b:customer:accounts:changestatus',
    CustomerAccountsBalanceLimit = 'b:customer:accounts:balancelimit',
    CustomerAccountsBalanceLimitAdd = 'b:customer:accounts:balancelimit:add',
    CustomerAccountsBalanceLimitModify = 'b:customer:accounts:balancelimit:modify',
    CustomerAccountsCounts = 'b:customer:accounts:counts',
    CustomerCards = 'b:customer:cards',
    CustomerCardsAdd = 'b:customer:cards:addcards',
    CustomerCardsChangeStatus = 'b:customer:cards:changestatus',
    CustomerAccountInitialLoadFunds = 'b:customer:accounts:initialload',
    CustomerAccountLoadFunds = 'b:customer:accounts:load',
    CustomerAccountUnloadFunds = 'b:customer:accounts:unload',
    CustomerCardsAccountStatement = 'b:customer:cards:AccountStatement',
    CustomerCardsCardDetails = 'b:customer:cards:carddetails',
    CustomerCardsCardDetailsModify = 'b:customer:cards:carddetails:modify',
    CustomerCardsSensitiveData = 'b:customer:cards:cardsensitivedata',
    CustomerCardsAuthSetting = 'b:customer:cards:authsetting',
    CustomerCardsAuthSettingModify = 'b:customer:cards:authsetting:modify',
    CustomerCardsVelocityLimits = 'b:customer:cards:velocitylimits',
    CustomerCardsVelocityLimitsModify = 'b:customer:cards:velocitylimits:modify',
    CustomerCardsVelocityLimitsCreate = 'b:customer:cards:velocitylimits:create',
    CustomerCardsVelocityLimitsLink = 'b:customer:cards:velocitylimits:linkvelocitylimit',
    CustomerCardsVelocityLimitsUnLink = 'b:customer:cards:velocitylimits:unlinkvelocitylimit',
    CustomerCardsTransactions = 'b:customer:cards:transactions',
    CustomerCardsReplaceCard = 'b:customer:cards:replacecard',
    CustomerCardsMarkRenewal = 'b:customer:cards:markrenewal',
    CustomerCardsAllowedMCCs = 'b:customer:cards:allowedmccs',
    CustomerCardsAllowedCountries = 'b:customer:cards:allowedcountries',

    // Product Management
    ProductManagement = 'b:productmgmt',
    CardProducts = 'b:productmgmt:cardproducts',
    CardProductsCreate = 'b:productmgmt:cardproducts:create',
    CardProductsModify = 'b:productmgmt:cardproducts:modify',
    CardProductsLinkBalanceLimit = 'b:productmgmt:cardproducts:linkbalancelimit',
    CardProductsLinkFeePlans = 'b:productmgmt:cardproducts:linkfeeplans',
    CardProductsVelocityLimit = 'b:productmgmt:cardproducts:velocitylimit',
    CardProductsBalanceLimit = 'b:productmgmt:cardproducts:balancelimit',
    CardProductsLinkVelocityLimit = 'b:productmgmt:cardproducts:velocitylimit:link',
    CardProductsLinkAdvancedVelocityLimit = 'b:productmgmt:advancevelocitylimits:link',
    CardProductsUnLinkAdvancedVelocityLimit = 'b:productmgmt:advancevelocitylimits:unlink',
    BalanceLimits = 'b:productmgmt:balancelimits',
    BalanceLimitsCreate = 'b:productmgmt:balancelimits:create',
    BalanceLimitsModify = 'b:productmgmt:balancelimits:modify',
    FeePlans = 'b:productmgmt:feeplans',
    FeePlansCreate = 'b:productmgmt:feeplans:create',
    FeePlansModify = 'b:productmgmt:feeplans:modify',
    Charges = 'b:productmgmt:charges',
    ChargesCreate = 'b:productsettings:charges:create',
    ChargesModify = 'b:productmgmt:charges:modify',
    VelocityLimits = 'b:productmgmt:velocitylimits',
    VelocityLimitsCreate = 'b:productmgmt:velocitylimits:create',
    VelocityLimitsModify = 'b:productmgmt:velocitylimits:modify',

    // Access Management
    AccessManagement = 'b:accessmgmt',
    Roles = 'b:accessmgmt:roles',
    RolesCreate = 'b:accessmgmt:roles:create',
    RolesModify = 'b:accessmgmt:roles:modify',
    Admins = 'b:accessmgmt:admins',
    AdminsCreate = 'b:accessmgmt:admins:create',
    AdminsModify = 'b:accessmgmt:admins:modify',
    AdminsResetPasswordAll = 'b:accessmgmt:admins:resetpassword:all',
    AdminsResetPassword = 'b:accessmgmt:admins:resetpassword',
    PasswordPolicy = 'b:accessmgmt:passwordpolicy',
    PasswordPolicyCreate = 'b:accessmgmt:passwordpolicy:create',
    PasswordPolicyModify = 'b:accessmgmt:passwordpolicy:modify',

    // SDK Settings
    SDKSettings = 'b:sdksettings',
    SDKSettingsCreate = 'b:sdksettings:create',
    SDKSettingsModify = 'b:sdksettings:modify',

    // OTP Settings
    OTPSettings = 'b:otpsettings',
    OTPSettingsCreate = 'b:otpsettings:create',
    OTPSettingsModify = 'b:otpsettings:modify',

    // Channel Settings
    ChannelSettings = 'b:channelsettings',
    ChannelSettingsCreate = 'b:channelsettings:create',
    ChannelSettingsModify = 'b:channelsettings:modify',

    // Tickets
    Tickets = 'b:tickets',
    TicketsCreate = 'b:tickets:create',
    TicketsModifyAll = 'b:tickets:modify:all',
    TicketsModify = 'b:tickets:modify',
    TicketsDelete = 'b:tickets:delete',
    TicketsRoles = 'b:tickets:roles',

    // Settings
    Settings = 'b:settings',
    Logo = 'b:logo',
    LogoCreate = 'b:logo:create',
    LogoModify = 'b:logo:modify',
    CardDesign = 'b:carddesign',
    CardDesignCreate = 'b:carddesign:create',
    CardDesignModify = 'b:carddesign:modify',
    SettingsAvatar = 'b:settings:avatar',
    SettingsAvatarCreate = 'b:settings:avatar:create',

    // Reports
    Reports = 'b:reports',
    AllowedCountries = 'b:allowedcountries',

    // Allowed lists
    AllowedMCCs = 'b:allowedmccs',
    AllowedMCCsCreate = 'b:reports:allowedmccs:create',
    AllowedMCCsModify = 'b:reports:allowedmccs:modify',

    // Product Settings
    ProductSettings = 'b:productsettings',
    FeePlansProductSettings = 'b:productsettings:feeplans',
    FeePlansProductSettingsCreate = 'b:productsettings:feeplans:create',
    FeePlansProductSettingsModify = 'b:productsettings:feeplans:modify',
    ChargesProductSettings = 'b:productsettings:charges',
    ChargesProductSettingsCreate = 'b:productsettings:charges:create',
    ChargesProductSettingsModify = 'b:productsettings:charges:modify',
    VelocityLimitsProductSettings = 'b:productsettings:velocitylimits',
    VelocityLimitsProductSettingsCreate = 'b:productsettings:velocitylimits:create',
    VelocityLimitsProductSettingsModify = 'b:productsettings:velocitylimits:modify',
    BalanceLimitsProductSettings = 'b:productsettings:balancelimits',
    BalanceLimitsProductSettingsCreate = 'b:productsettings:balancelimits:create',
    BalanceLimitsProductSettingsModify = 'b:productsettings:balancelimits:modify',
    AdvanceVelocityLimitsProductSettings = 'b:productsettings:advancevelocitylimits',
    AdvanceVelocityLimitsProductSettingsCreate = 'b:productsettings:advancevelocitylimits:create',
    AdvanceVelocityLimitsProductSettingsModify = 'b:productsettings:advancevelocitylimits:modify',
    NotificationTemplatesProductSettings = 'b:productsettings:notificationtemplates',
    NotificationTemplatesProductSettingsCreate = 'b:productsettings:notificationtemplates:create',
    NotificationTemplatesProductSettingsModify = 'b:productsettings:notificationtemplates:modify',
    WebhooksProductSettings = 'b:productsettings:webhooks',
    WebhooksProductSettingsCreate = 'b:productsettings:webhooks:create',
    WebhooksProductSettingsModify = 'b:productsettings:webhooks:modify',
    MCCGroupProductSettings = 'b:productsettings:mccgroup',
    MCCGroupModifyProductSettings = 'b:productsettings:mccgroup:modify',
    MCCGroupCreateProductSettings = 'b:productsettings:mccgroup:create',
    OTPSettingsProductSettings = 'b:productsettings:otpsettings',
    OTPSettingsProductSettingsCreate = 'b:productsettings:otpsettings:create',
    OTPSettingsProductSettingsModify = 'b:productsettings:otpsettings:modify',

    // Transactions
    Transactions = 'b:transactions',
    TransactionDetails = 'b:transactions:details',

    // Card Designs
    CardDesigns = 'b:carddesign',
    CardDesignsCreate = 'b:carddesign:create',
    CardDesignsModify = 'b:carddesign:modify',

    // Card Details
    Cards = 'b:cards',
    CardChangeStatus = 'b:cards:changestatus',
    CardDetails = 'b:cards:carddetails',
    CardDetailsModify = 'b:cards:carddetails:modify',
    CardAccountStatement = 'b:cards:accountStatement',
    CardVelocityLimits = 'b:cards:velocitylimits',
    CardVelocityLimitsLink = 'b:cards:velocitylimits:linkvelocitylimit',
    CardAuthSetting = 'b:cards:authsetting',

    // Bulk Upload
    BulkUpload = 'b:bulkload',
    BulkUploadFileUpload = 'b:bulkload:fileupload',
    BulkUploadFileData = 'b:bulkload:filedata',
    BulkUploadExecuteData = 'b:bulkload:executeload',

    // Locations
    Locations = 'b:locations',
    LocationsCreate = 'b:locations:create',
    LocationsDelete = 'b:locations:delete',
    LocationsUpdate = 'b:locations:update',

    CardLessVouchers = 'b:cardless:vouchers',

    // Call Center
    CallCenter = 'b:callcenter',

    // Report
    Report = 'b:report',
}
