// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api_url: 'https://api.dev.platform.nm-2.nymcard.com/',
    version: 'beta',
    apiKey: 'tenant0_api',
    xConsumerUsername: 'tenant0_api',
    agentRoleId: '',
};

export const tenantSetting = [
    { key: 'tenant1', name: 'tenant1' },
    { key: 'tenant3', name: 'tenant3' },
    { key: 'tenant2', name: 'tenant2' },
    { key: 'tenant0', name: 'tenant0' },
];

export const customerTenant = {
    FIB: 'tenant6',
    SAMSUNG: 'tenant18',
    INC: 'tenant7',
    NASS: 'tenant37',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
