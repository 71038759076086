export enum EventScope {
    CARD_STATUS_CHANGE = 'CARD_STATUS_CHANGE',
    TRANSACTION = 'TRANSACTION',
    KYC_RESULT = 'KYC_RESULT',
    USER_CREATED = 'USER_CREATED',
    USER_STATUS_CHANGE = 'USER_STATUS_CHANGE',
    USER_DOCUMENT_ALERT = 'USER_DOCUMENT_ALERT',
    IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
    PIN_CHANGE = 'PIN_CHANGE',
    EXPIRED_AUTH = 'EXPIRED_AUTH',
    CHARGE_APPLIED = 'CHARGE_APPLIED',
}
export interface CustomHeader {
    header_name?: string;
    header_value?: string;
}

export interface Config {
    basic_auth_username?: string;
    basic_auth_password?: string;
    custom_headers?: Array<CustomHeader>;
}

export interface IWebHooks {
    id: string;
    endpoint: string;
    active: boolean;
    events: Array<EventScope>;
    config: Config;
    modified: any;
}

export type IMutateWebHook = Omit<IWebHooks, 'created' | 'modified'>;
